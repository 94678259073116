import {RouteRecordRaw} from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/relatorios',
        name: 'reports',
        component: () => import(/* webpackChunkName: "reports" */ '@/modules/reports/views/DashBoards.vue'),
    },
]

export default routes
