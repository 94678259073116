import { App } from 'vue'
import AlertHelper from '@/helpers/AlertHelper'
import Date from '@/helpers/date'
import Format from '@/helpers/format'
import Preloader from '@/helpers/preloader'
import Event from '@/helpers/event'
import permission from '@/app/permission'
import feature from '@/app/feature'
import setting from '@/app/setting'
import helper from '@/helpers/helper'
import i18n from "@/i18n";

const environment = {
  skipCount: '0',
  maxResultCount: '50',
}

export default {
  install: (app: App) => {
    app.config.globalProperties.$date = Date
    app.config.globalProperties.$format = Format
    app.config.globalProperties.$alert = AlertHelper
    app.config.globalProperties.$event = Event
    app.config.globalProperties.$preloader = Preloader
    app.config.globalProperties.$helper = helper
    app.config.globalProperties.$ = require('jquery')
    app.config.globalProperties.$permission = permission
    app.config.globalProperties.$feature = feature
    app.config.globalProperties.$setting = setting
    app.config.globalProperties.$environment = environment
    app.config.globalProperties.window = window

    app.provide('date', Date)
    app.provide('format', Format)
    app.provide('alert', AlertHelper)
    app.provide('event', Event)
    app.provide('helper', helper)
    app.provide('preloader', Preloader)
    app.provide('permission', permission)
    app.provide('feature', feature)
    app.provide('t', i18n.global.t)
    app.provide('setting', setting)
    app.provide('environment', environment)
  },
}
