import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/conciliations',
    name: 'conciliations',
    component: () => import(/* webpackChunkName: "conciliations" */ '@/modules/conciliations/views/ConciliationManagement.vue'),
  }
]

export default routes
