import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["data-testid", "disabled"]
const _hoisted_2 = {
  class: "d-flex justify-content-center align-items-center",
  style: {"width":"max-content"}
}
const _hoisted_3 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_4 = { class: "d-flex justify-content-center align-items-center" }
const _hoisted_5 = {
  key: 0,
  class: "d-flex align-items-center"
}
const _hoisted_6 = {
  key: 1,
  class: "d-flex"
}
const _hoisted_7 = {
  key: 1,
  class: "d-flex justify-content-center align-items-center ath-ml-8"
}
const _hoisted_8 = {
  key: 0,
  class: "sup"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ath_icon = _resolveComponent("ath-icon")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({
    class: ["ath-button d-flex justify-content-center align-items-center", _ctx.$attrs.disabled || _ctx.loading ? 'disabled' : ''],
    "data-testid": `button-${_ctx.dataTestid}`
  }, _ctx.$attrs, { disabled: _ctx.loading }), [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.iconLeft && !_ctx.loading)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createVNode(_component_ath_icon, {
              icon: _ctx.iconLeft,
              "font-size": _ctx.iconSize,
              class: "icon-btn ath-mr-4"
            }, null, 8, ["icon", "font-size"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        (!_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "default", {}, () => [
                _cache[0] || (_cache[0] = _createElementVNode("span", null, "ND", -1))
              ], true)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[1] || (_cache[1] = [
              _createElementVNode("span", {
                class: "spinner-border spinner-border",
                role: "status",
                "aria-hidden": "true"
              }, null, -1)
            ])))
      ]),
      _renderSlot(_ctx.$slots, "right", {}, undefined, true),
      (_ctx.iconRight && !_ctx.loading)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
            _createVNode(_component_ath_icon, {
              icon: _ctx.iconRight,
              "font-size": _ctx.iconSize,
              class: "icon-btn"
            }, null, 8, ["icon", "font-size"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.sup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.sup), 1))
      : _createCommentVNode("", true)
  ], 16, _hoisted_1))
}