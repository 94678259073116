import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/atividades',
    name: 'activities',
    component: () => import(/* webpackChunkName: "activities" */ '@/modules/activities/views/ActivitiesList.vue'),
  },
  {
    path: '/atividades/:id',
    name: 'activitiesDetails',
    component: () => import(/* webpackChunkName: "activities" */ '@/modules/activities/views/CorrectionDetails.vue'),
  },
]

export default routes
