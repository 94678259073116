const SingletonInstanceSymbol = Symbol();
const InstanceSymbol = Symbol();

let settings = {};

class Settings {
  constructor(instance) {
    if (instance !== SingletonInstanceSymbol) {
      throw new Error('Cannot Construct Setting Instance');
    }
  }

  static get instance() {
    if (!this[InstanceSymbol]) {
      this[InstanceSymbol] = new Settings(SingletonInstanceSymbol);
    }
    return this[InstanceSymbol];
  }

  registry(listOfSettings) {
    settings = listOfSettings;
  }

  getSetting(setting) {
    if (settings[setting]) {
      return settings[setting]
    }
    return null;
  }
}

export default Settings.instance;
