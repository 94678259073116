import {RouteRecordRaw} from 'vue-router'

const terms: RouteRecordRaw = {
  meta: {requiresAuth: true},
  path: '/terms',
  name: 'terms',
  component: () => import(/* webpackChunkName: "login" */ '@/modules/terms/AcceptanceTerms.vue'),
}

const statementOfResponsibility: RouteRecordRaw = {
  meta: {requiresAuth: true},
  path: '/statementOfResponsibility',
  name: 'statementOfResponsibility',
  component: () => import(/* webpackChunkName: "login" */ '@/modules/terms/StatementOfResponsibility.vue'),
}

export {terms, statementOfResponsibility}
