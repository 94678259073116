import {Router} from "vue-router";
import AcademicClass from '@/modules/academicClass/router'
import Integration from '@/modules/integration/router'
import Configurations from '@/modules/configurations/router'
import TransfersOld from '@/modules/transfers-old/router'
import Conciliation from "@/modules/conciliations/router";
import Cessions from "@/modules/cessions/router";
import Billing from "@/modules/billing/router";
import Dashboard from "@/modules/reports/router";
import Repasses from "@/modules/repasses/router";
import {terms, statementOfResponsibility} from "@/modules/terms/router";
import Users from "@/modules/users/router";
import Invoices, {invoiceCustom} from "@/modules/invoice/router";
import Activities from "@/modules/activities/router";
import StudentArea from "@/modules/student-area/router";

const installRoutes = (router: Router) => {
  router.addRoute(
    {
      path: '/',
      name: 'main',
      component: () => import(/* webpackChunkName: "main" */ '@/Main.vue'),
      meta: {requiresAuth: true},
      children: [
        ...Invoices,
        ...AcademicClass,
        ...Integration,
        ...Configurations,
        ...TransfersOld,
        ...Conciliation,
        ...Cessions,
        ...Billing,
        ...Dashboard,
        ...Repasses,
        ...Activities,
        ...StudentArea,
        {
          path: '/minhaconta',
          name: 'myAccount',
          component: () => import(/* webpackChunkName: "academic-class" */ '@/modules/users/views/MyAccount.vue'),
        },
      ],
    },
  )

  router.addRoute({...invoiceCustom})

  router.addRoute({...terms})

  router.addRoute({...statementOfResponsibility})

  router.addRoute({
      path: '/signin-callback',
      name: 'signinCallback',
      component: () => import(/* webpackChunkName: "login" */ '@/modules/security/SigninCallback.vue'),
    },
  )

  router.addRoute({
      path: '/unauthorized',
      name: 'unauthorized',
      component: () => import(/* webpackChunkName: "login" */ '@/modules/security/Unauthorized.vue'),
    },
  )

  router.addRoute({...Users})

  router.addRoute({
      path: '/report-pdf-resume-yearly-repasse/:id/:year/:planId',
      name: 'pdfResumeYearlRepasse',
      component: () => import(/* webpackChunkName: "login" */ '@/modules/reports/components/ReportPdfYearlyRepasse.vue'),
    },
  )
}

export default installRoutes
