import Alert from "@/helpers/AlertHelper";
import preloader from "@/helpers/preloader";

export default class ServiceError extends Error {
  constructor(service, error) {
    super()
    this.name = 'Educbank Service';
    this.response = {
      path: service,
      error,
      message: error?.response?.data?.error?.message
    }
  }
}

const errorProvider = async (error) => {
  const responseUrl = error?.response.request.responseURL
  const host = process.env.VUE_APP_PROVIDER_GATEWAY
  const pathRoute = responseUrl.split(`${ host }/`)[1];

  const preloaderBlock = document.querySelector('.block-ui');
  if (preloaderBlock)
    await preloader.hide()

  const message = error?.response?.data?.error?.message ?
    error?.response?.data :
    await new Response(error?.response?.data).json()

  Alert.notify('error', message?.error?.message, message?.error?.details);
  return Promise.reject(new ServiceError(pathRoute, error));
}


export { errorProvider }
