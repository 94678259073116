import permission from '@/app/permission';
import feature from '@/app/feature';
import setting from '@/app/setting';
import provider from '@/provider';
import i18n from '@/i18n';
import { UserActionTypes, userStore } from '@/modules/users/store';
import educbankService from '@/services/educbankService';
import localforage from 'localforage';
import subdomain from '@/helpers/subdomain';
import { capitalize } from '@/helpers/string';
import IntegrationOidc from '@/app/integrations/oidc';
import userService from "@/modules/users/services/userService";
import settingsService from "@/modules/configurations/parameters/services/settingsService";

class LoadBackendConfigurationError extends Error {
  constructor(message) {
    super(`\n Message: ${ message } \n\n`);
    this.name = 'Educbank web template -> loadConfiguration: ';
  }
}

const service = async () => {
  try {
    const response = await provider.core.get('/api/abp/application-configuration');
    const result = response.data;
    const currentTenant = {
      ...result.currentTenant,
      currentTenantIsReadOnly: !!result?.extraProperties?.currentTenantIsReadOnly
    }

    await userStore.dispatch(UserActionTypes.CHANGE_IN_ADMIN_GROUP, await userService.userInGroupAdmin(result.currentUser.id));
    await userStore.dispatch(UserActionTypes.SET_USER_TENANT, currentTenant);

    await permission.registry(result.auth.grantedPolicies);
    feature.registry(result.features.values);

    const tenantLocalForage = JSON.parse(await localforage.getItem('tenantSelected'));

    if (permission.isHost() && tenantLocalForage?.id) {
      setting.registry(await settingsService.getSettingsForTenant(tenantLocalForage?.id));
    } else {
      setting.registry(result.setting.values);
    }

    i18n.global.locale.value = result.localization.currentCulture.cultureName;

    if (permission.isHost()) {
      if (permission.hasPermission('Educbank.Tenant.GetAll')) {
        await educbankService.getAllTenants(`?SkipCount=${ userStore.state.user.skipCountTenant }&MaxResultCount=${ userStore.state.user.maxResultCountTenant }`).then(async (tenants) => {
          tenants.items.unshift({ id: '', name: 'Global', displayName: 'Global' });
          if (tenantLocalForage) {
            const found = tenants.items.find(item => {
              return item.id === tenantLocalForage.id;
            });

            if (!found) {
              tenants.items.push(tenantLocalForage);
            }
            userStore.dispatch(UserActionTypes.SET_USER_TENANT_SELECTED, tenantLocalForage);
          } else {
            userStore.dispatch(UserActionTypes.SET_USER_TENANT_SELECTED, tenants.items[0]);
          }

          userStore.dispatch(UserActionTypes.SET_USER_All_TENANTS, tenants);
        });

        if (userStore.state.user.tenantSelected.name !== 'Global') {
          const response = await provider.core.get('/api/abp/application-configuration');
          const result = response.data;
          feature.registry(result.features.values);
        }
      } else {
        userStore.dispatch(UserActionTypes.SET_USER_TENANT_SELECTED, {
          id: '', name: 'Global', displayName: 'Global'
        });
      }
    }

    if (userStore.state.user.tenantSelected) {
      document.title = userStore.state.user.tenantSelected.displayName || userStore.state.user.tenantSelected.name;
    } else {
      document.title = `${ subdomain.replace('.', '') }`;
      document.title = capitalize(document.title);
    }
  } catch (e) {
    await IntegrationOidc.logout()
    throw new LoadBackendConfigurationError(`can't loadBackendConfigurations in app/config. \n\n${ e }`);
  }
};

export default service;
