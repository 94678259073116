export enum NotificationEventStatusEnum {
  InProgress = 'InProgress',
  Success = 'Success',
  Failed = 'Failed',
  Error = 'Error'
}

export enum NotificationEventTypeEnum {
  ImportInvoice = 'ImportInvoice',
  ExportInvoice = 'ExportInvoice',
  ImportEnrollment = 'ImportEnrollment',
}
