import {RouteRecordRaw} from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/repasses',
    name: 'repassesList',
    component: () => import(/* webpackChunkName: "repasses" */ '@/modules/repasses/views/RepassesList.vue'),
  },
  {
    path: '/repasses/details',
    name: 'repasseDetails',
    component: () => import(/* webpackChunkName: "repasses" */ '@/modules/repasses/views/RepasseDetails.vue'),
  },
]

export default routes
