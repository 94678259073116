// @ts-ignore
import {RouteRecordRaw} from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/cessoes',
        meta: { permission: 'Cession.CessionItem' },
        name: 'cessions',
        component: () => import(/* webpackChunkName: "cessions" */ '@/modules/cessions/views/CessionManagement.vue'),
    },
    {
        path: '/cessoes/:id',
        name: 'cessionDetails',
        component: () => import(/* webpackChunkName: "cessions-details" */ '@/modules/cessions/views/CessionDetails.vue'),
    },
]

export default routes
