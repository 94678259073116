import eventHub from './eventHub';

export default class Event {
	/**
	 * Emit the given event.
	 *
	 * @param {string|object} event
	 * @param {...*} args
	 */
	static emit(event, ...args){
		eventHub.$emit(event, ...args);
	}

	/**
	 * Listen for the given event.
	 *
	 * @param {string} event
	 * @param {function} callback
	 */
	static listen(event, callback){
		eventHub.$on(event, callback);
	}

	/**
	 * Remove one or more event listeners.
	 *
	 * @param {string} event
	 */
	static async remove(event){
		await eventHub.$off(event);
	}
}
