import { useUserState } from '@/modules/users/store';

const SingletonInstanceSymbol = Symbol();
const InstanceSymbol = Symbol();

let permissions = {};

class Permissions {
  constructor(instance) {
    if (instance !== SingletonInstanceSymbol) {
      throw new Error('Cannot Construct Permission Instance');
    }
  }

  static get instance() {
    if (!this[InstanceSymbol]) {
      this[InstanceSymbol] = new Permissions(SingletonInstanceSymbol);
    }
    return this[InstanceSymbol];
  }

  registry(listOfPermissions) {
    permissions = listOfPermissions;
  }

  hasPermission(permission) {
    return permissions[permission] ?? false
  }

  isHost() {
    return !useUserState()?.tenant?.id;
  }

  forChangeData() {
    if (!this.isHost()) return true
    return useUserState()?.tenantSelected && useUserState()?.tenantSelected?.id
  }

  isTenantSelectedIsGlobal() {
    return useUserState()?.tenantSelected && useUserState()?.tenantSelected?.name === 'Global'
  }
}

export default Permissions.instance;
