import {createWebHistory, createRouter} from "vue-router";
import installRoutes from "@/app/router";
import verifyAuthBeforeEachRouterEnter from "@/app/router/verifyAuthBeforeEachRouterEnter";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [],
  linkExactActiveClass: 'active',
})

installRoutes(router)
verifyAuthBeforeEachRouterEnter(router)

export default router
