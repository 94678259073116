<template>
  <div v-if="!onLine" class="box-offline ath-text-subtitle-small">{{ $t('MsgOffline') }}</div>
  <ath-loader/>
  <router-view/>
</template>

<script>
import { defineComponent } from "vue";
import '@componentt/toast/dist/toast/toast.css';
import $ from 'jquery'
import AthLoader from "@/components/loader/AthLoader.vue";

export default defineComponent({
  name: 'app',
  components: { AthLoader },
  data: () => ({
    isMobileView: false,
    onLine: navigator.onLine
  }),
  beforeMount() {
    if (process.env.VUE_APP_EDUCBANK_ENVIRONMENT?.toLowerCase() === 'production') {
      this.addHotjarAnalytics()
      this.addGoogleAnalytics()
      this.addAmplifySegmentAnalytics()
      this.addUserGuiding()
    }
  },
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  created() {
    this.handleView();
    window.addEventListener("resize", this.myEventHandler);
  },
  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  methods: {
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === 'online';
    },
    handleView() {
      this.isMobileView = window.innerWidth <= 990;
    },
    myEventHandler() {
      this.handleView();
    },
    toogleSidebar() {
      $('#sidebar').toggleClass('active-sidebar');
      $('#content-body').toggleClass('active-body');
      $('#portal-footer-body').toggleClass('active-body');
      $('#portal-footer-app').toggleClass('active-body');
    },
    addHotjarAnalytics() {
      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');
      script.innerHTML = "(function(h,o,t,j,a,r){\n" +
          "          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};\n" +
          "          h._hjSettings={hjid:" + process.env.VUE_APP_HOTJAR_ID + ",hjsv:6};\n" +
          "          a=o.getElementsByTagName('head')[0];\n" +
          "          r=o.createElement('script');r.async=1;\n" +
          "          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;\n" +
          "          a.appendChild(r);\n" +
          "        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')";
      head.appendChild(script);
    },
    addGoogleAnalytics() {
      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');
      const scriptInner = document.createElement('script');

      script.async = true
      script.src = `https://www.googletagmanager.com/gtag/js?id=${ process.env.VUE_APP_GOOGLE_ANALYTICS_ID }`
      head.appendChild(script);

      scriptInner.innerHTML = "window.dataLayer = window.dataLayer || [];\n" +
          "  function gtag(){dataLayer.push(arguments);}\n" +
          "  gtag('js', new Date());\n" +
          "  gtag('config', '" + process.env.VUE_APP_GOOGLE_ANALYTICS_ID + "');";
      head.appendChild(scriptInner);
    },
    addAmplifySegmentAnalytics() {
      const head = document.getElementsByTagName('head')[0];
      const scriptInner = document.createElement('script');

      scriptInner.innerHTML = "!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)" +
          "if(analytics.invoked)window.console&&console.error&&console.error(\"Segment snippet included twice.\");" +
          "else{analytics.invoked=!0;analytics.methods=[\"trackSubmit\",\"trackClick\",\"trackLink\",\"trackForm\"," +
          "\"pageview\",\"identify\",\"reset\",\"group\",\"track\",\"ready\",\"alias\",\"debug\",\"page\",\"once\"," +
          "\"off\",\"on\",\"addSourceMiddleware\",\"addIntegrationMiddleware\",\"setAnonymousId\",\"addDestinationMiddleware\"];" +
          "analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);" +
          "analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];" +
          "analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement(\"script\");" +
          "t.type=\"text/javascript\";t.async=!0;t.src=\"https://cdn.segment.com/analytics.js/v1/\" + key + \"/analytics.min.js\";" +
          "var n=document.getElementsByTagName(\"script\")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};" +
          "analytics._writeKey=\"" + process.env.VUE_APP_SEGMENT_ANALYTICS_ID + "\";;analytics.SNIPPET_VERSION=\"4.15.3\";\n" +
          "analytics.load(\"" + process.env.VUE_APP_SEGMENT_ANALYTICS_ID + "\");\n" +
          "}}();";
      head.appendChild(scriptInner);
    },
    addUserGuiding() {
      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');
      script.innerHTML = "(function(g,u,i,d,e,s){g[e]=g[e]||[];" +
          "var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);" +
          "k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';" +
          "f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};" +
          "ug.c=function(n){return function(){ug.q.push([n,arguments])};};" +
          "var m=['previewGuide','finishPreview','track','identify', 'hideChecklist','launchChecklist'];" +
          "for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','" + process.env.VUE_APP_USERGUIDING_ID + "');";
      head.appendChild(script);
    },
  }
});
</script>
<style lang="scss" scoped>
.box-offline {
  background: var(--ath-color-danger);
  color: var(--ath-color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
}
</style>
