import { useUserState } from '@/modules/users/store';

const SingletonInstanceSymbol = Symbol();
const InstanceSymbol = Symbol();

let features = {};

class Features {
  constructor(instance) {
    if (instance !== SingletonInstanceSymbol) {
      throw new Error('Cannot Construct Feature Instance');
    }
  }

  static get instance() {
    if (!this[InstanceSymbol]) {
      this[InstanceSymbol] = new Features(SingletonInstanceSymbol);
    }
    return this[InstanceSymbol];
  }

  registry(listOfFeatures) {
    features = listOfFeatures;
  }

  hasFeature(feature) {
    const isAdminEducbank = useUserState().email.indexOf('educbank.com.br') > -1;

    return features[feature] || isAdminEducbank;
  }

  isHost() {
    return !useUserState().tenant.id;
  }
}

export default Features.instance;
