import AthLoader from '@/components/loader/AthLoader';
import { createApp } from "vue";

let appLoader;

const preloader = () => {
  return {
    show(text = null, sizeValue = 'lg') {
      const props = {
        text: text,
        size: sizeValue
      };
      appLoader = createApp(AthLoader, props)
      appLoader.mount('#blockui');
    },
    hide() {
      return new Promise((resolve, reject) => {
        try {
          const preloaderBlock = document.querySelector('.block-ui');
          if (preloaderBlock) {
            preloaderBlock.remove();
            appLoader?.unmount()
          }
          return resolve();
        } catch (e) {
          console.log(e);
          return reject();
        }
      });
    }
  };
};
export default preloader();
