/**
 * This file is a SCRIPT for initialize the providers in services
 */

import provider from '@/provider';
import axios from 'axios';
import subdomain from "@/helpers/subdomain";
import { GATEWAYAUTH } from "@/app/config/providers/api/identity";
import { GATEWAY } from "@/app/config/providers/api/core";
import { CESSION } from "@/app/config/providers/api/cession";
import { GATEWAYTRANSFER } from "@/app/config/providers/api/zeroDefault";
import { CREDIT } from "@/app/config/providers/api/credit";
import { PAGARME } from "@/app/config/providers/api/pagarme";
import { COURIER } from "@/app/config/providers/api/courier";

const PUBLIC = axios.create({
  baseURL: `${ process.env.VUE_APP_PROTOCOL }${ subdomain }${ process.env.VUE_APP_PROVIDER_GATEWAY }`,
});

const INVOICE_VIEW = axios.create({
  baseURL: `${ process.env.VUE_APP_PROTOCOL }${ subdomain }${ process.env.VUE_APP_PROVIDER_INVOICE_VIEW }/api/ebpay`,
});

const initializeProviders = () => {
  provider.addProvider('public', PUBLIC);
  provider.addProvider('invoiceview', INVOICE_VIEW);
  provider.addProvider('core', GATEWAY);
  provider.addProvider('identity', GATEWAYAUTH);
  provider.addProvider('gatewaytransfer', GATEWAYTRANSFER);
  provider.addProvider('cession', CESSION);
  provider.addProvider('credit', CREDIT);
  provider.addProvider('pagarme', PAGARME);
  provider.addProvider('courier', COURIER);
}

export default initializeProviders
