import provider from '@/provider';
import Helper from '../../../helpers/helper';
import Date from '../../../helpers/date';
import subdomain from '@/helpers/subdomain';
import qs from 'qs';
// @ts-ignore
import {v4 as uuidv4} from 'uuid';
import {InvoiceComment, InvoiceCore, InvoiceEarlyPaymentDiscount, InvoicePayer} from "@/modules/invoice/types/invoice.types";
import Format from "@/helpers/format";
import {EnrollmentCore} from "@/modules/enrollment/types/enrollment.types";
import {OptionsSelectInput} from "@/components/inputs/inputs";

export interface TransactionAdjustmentCore {
  externalDescription?: string
  internalDescription?: string
  valueCents?: string
  valueCentsUnmasked: number
  occurrenceDate: string
  transactionType: string
  transactionSide: string
  writeBothSides: boolean
}

export interface CreditCardCore {
  number: string
  name: string
  dueDate: string
  cvc: string
  installments: number
}

export interface ItemsInvoiceCore {
  academicClassLevel?: string
  academicClassName?: string
  academicClassYear?: number
  creditCardTaxCents?: number
  itemName: string
  quantity: number
  studentExternalId?: string
  studentName?: string
  totalInstallments?: number
  valueCents?: number
  valueCentsUnmasked?: number
  isEnable?: boolean
  newValueCents?: string
  newValueCentsUnmasked?: number
  netAmountCents?: string
  netAmountCentsUnmasked?: number
  installmentNumber?: number
  externalOrderId?: string
  ebpayPlan?: string
  id?: string
  enrollmentId?: string
  schoolId?: string
  isAdditionalItem?: boolean
}

const service = () => {
  return {
    baseUrl: '/api/ebpay/invoice',

    async getAllInvoices(filter = '') {
      const response = await provider.core.get(`${this.baseUrl}${filter}`);
      return response.data.items;
    },

    async createOneInvoice(invoice: InvoiceCore, enrollmentId = '', companyCnpj = '') {
      const items: ItemsInvoiceCore[] = [];
      const cloneItems: ItemsInvoiceCore[] = Helper.cloneObject(invoice.items);

      const paymentFine = {
        overdueFine: Helper.sentPercent(invoice.paymentFine.overdueFineUnmasked),
        dailyInterest: Helper.sentPercent(invoice.paymentFine.dailyInterestUnmasked),
      };

      cloneItems.forEach((item) => {
        items.push({
          itemName: item.itemName,
          quantity: 1,
          valueCents: item.valueCentsUnmasked,
          installmentNumber: item.installmentNumber,
          totalInstallments: item.totalInstallments,
          enrollmentId,
          ebpayPlan: invoice.ebpayPlan,
        });
      });

      const earlyPaymentDiscounts = this.formatEarlyePaymentDiscount(invoice.earlyPaymentDiscount);

      return provider.core.post(`/api/ebpay/invoice`, {
        externalId: uuidv4(),
        payerDocument: `${Format.onlyNumberOfString(invoice.payer.document, false)}`,
        companyCnpj,
        dueDate: invoice.dueDate,
        items,
        earlyPaymentDiscounts,
        paymentFine,
      });
    },

    async getStatusStudentInvoice(enrollments: EnrollmentCore[]): Promise<{ key: string; status: string }[]> {
      if (enrollments.length === 0) return [];
      const Ids: { StudentId: string[] } = {StudentId: []};
      enrollments.forEach((enrollment) => {
        Ids.StudentId.push(enrollment.id);
      });

      const queryParams = qs.stringify(Ids);

      const response = await provider.core.get(`/api/ebpay/invoice/financial-status?${queryParams}`);
      return response.data;
    },

    async getNewPaymentFine(id: string, newDueDate: string, OverdueFine: number, DailyInterest: number) {
      const data = {
        newDueDate: Date.getDateFormat(newDueDate, 'yyyy-MM-dd'),
        OverdueFine: Helper.sentPercent(OverdueFine),
        DailyInterest: Helper.sentPercent(DailyInterest),
      };
      const queryParams = qs.stringify(data);

      const response = await provider.core.get(`/api/ebpay/invoice/${id}/payment-fine?${queryParams}`);
      return response.data;
    },

    async getStatusInvoices(filters: any) {
      const _filters: any = {};
      filters.map((item: any) => {
        if (item.field === 'DateFilter') {
          _filters['InitialDate'] = `${item.value.start.toFormat('yyyy-MM-dd')}`;
          _filters['EndDate'] = `${item.value.end.toFormat('yyyy-MM-dd')}`;
          _filters['filterBy'] = `${item.value.filterBy}`;
        }
      });
      const queryParams = qs.stringify(_filters);
      const response = await provider.core.get(`/api/ebpay/invoice/invoices-status?${queryParams}`);
      return response.data;
    },

    async getInvoiceId(id: string) {
      const response = await provider.core.get(`/api/ebpay/invoice/${id}`);
      return response.data;
    },

    async getInvoiceBankSlipData(id: string) {
      const response = await provider.public.get(`/api/ebpay/invoice/${id}/invoice-bank-slip-data`);
      return response.data;
    },

    async getPaymentMethods() {
      const response = await provider.core.get(`/api/ebpay/invoice/invoice-payment-methods`);
      return response.data;
    },

    async getHistoryInvoice(invoiceId: string) {
      const response = await provider.core.get(`api/ebpay/invoice/invoice-history/${invoiceId}`);
      return response.data;
    },

    async getRemessas(invoiceId: string) {
      const response = await provider.core.get(`api/ebpay/invoice/${invoiceId}/remessas`);
      return response.data;
    },

    async getRetornos(invoiceId: string) {
      const response = await provider.core.get(`api/ebpay/invoice/${invoiceId}/retornos`);
      return response.data;
    },

    async closeManyInvoices(date: { start: string; end: string }) {
      await provider.core.post(`/api/ebpay/invoice/close-many`, {
        fromDate: date.start,
        toDate: date.end,
      });
    },

    async sendPaymentSchool(invoiceId: string, paymentDate: string, totalPaidCents: number) {
      const response = await provider.core.post(`/api/ebpay/invoice/${invoiceId}/pay`, {
        paidDate: paymentDate,
        totalPaidCents
      });
      return response.data;
    },

    async cancelInvoice(id: string, comment: string) {
      await provider.core.post(`api/ebpay/invoice/${id}/cancel`, {
        comment: comment
      });
    },

    async closeInvoice(id: string) {
      await provider.core.post(`api/ebpay/invoice/${id}/close-sync`);
    },

    async exportInvoices(date: any, filterBy: string) {
      const url = `/api/financial/invoice/export-xlsx?initialDate=${date.start.toFormat('yyyy-MM-dd')}&endDate=${date.end.toFormat('yyyy-MM-dd')}&filterBy=${filterBy}`;
      await provider.core.get(url,
        {responseType: 'arraybuffer'}).then((response: any) => {
        Helper.downloadFile(response, `${subdomain.replace('.', '')}-${Date.getDate(date.start)}-
                        ${Date.getDate(date.end)}---${Date.timeNow()}---`)
      });
    },

    async updateInvoice(id: string,
                        earlyPaymentDiscountsData: InvoiceEarlyPaymentDiscount[],
                        paymentFineData: {
                          overdueFineUnmasked: number
                          dailyInterestUnmasked: number
                          isEnabled: boolean
                        },
                        dueDate: string,
                        itemsInvoice: ItemsInvoiceCore[],
                        payerUpdate: InvoicePayer,
                        reason = '',
                        charges?: number) {
      const earlyPaymentDiscounts: InvoiceEarlyPaymentDiscount[] = [];

      earlyPaymentDiscountsData.forEach(earlyPaymentDiscountData => {
        const valueDiscount = earlyPaymentDiscountData ? earlyPaymentDiscountData.discount!.valueUnmasked : 0;
        earlyPaymentDiscounts.push({
          days: earlyPaymentDiscountData.days ?? 0,
          percent: earlyPaymentDiscountData.discount!.unit === 'percentual' ?
            +Helper.sentPercent(valueDiscount) : undefined,
          valueCents: earlyPaymentDiscountData.discount!.unit === 'cents' ? valueDiscount : 0,
        });
      });

      let paymentFine = undefined;
      if (paymentFineData.isEnabled) {
        paymentFine = {
          overdueFine: Helper.sentPercent(paymentFineData.overdueFineUnmasked),
          dailyInterest: Helper.sentPercent(paymentFineData.dailyInterestUnmasked),
        };
      }

      const items: ItemsInvoiceCore[] = [];

      itemsInvoice.forEach((item) => {
        if (item.isEnable) {
          const newValueInCents = item.newValueCentsUnmasked ?
            item.newValueCentsUnmasked :
            item.netAmountCentsUnmasked;
          items.push({
              valueCents: newValueInCents,
              itemName: item.itemName,
              quantity: item.quantity,
              installmentNumber: item.installmentNumber,
              totalInstallments: item.totalInstallments,
              externalOrderId: item.externalOrderId,
              studentExternalId: item.studentExternalId,
              studentName: item.studentName,
              academicClassName: item.academicClassName,
              academicClassYear: item.academicClassYear,
              academicClassLevel: item.academicClassLevel,
              ebpayPlan: item.ebpayPlan,
              enrollmentId: item.enrollmentId,
            }
          );
        }
      });

      const response = await provider.core.put(`/api/ebpay/invoice/${id}`, {
        paymentFine,
        dueDate,
        earlyPaymentDiscounts,
        items,
        reason,
        charges: charges === 0 ? null : charges,
        payerDocument: `${Format.onlyNumberOfString(payerUpdate.cpfCnpj, false)}`,
      });
      return response.data;
    },

    async sendInvoicesToPdf(data: any, onlyOne = false, isFromEnrollment = false, propObj = 'id') {
      const dataObject = {
        ids: [] as string[],
        isFromEnrollment,
      };

      if (onlyOne) {
        dataObject.ids[0] = data
      } else {
        for (const element of data) {
          dataObject.ids.push(element.getData()[propObj]);
        }
      }

      const response = await provider.core.post('/api/ebpay/invoices-download-request', dataObject);
      return response.data;
    },

    async checkDonwloadIsFinished(invoiceDownloadRequestId: string) {
      const urlPdf = `/api/ebpay/invoices-download-request/${invoiceDownloadRequestId}`;
      const response = await provider.core.get(urlPdf);
      return response.data;
    },

    formatEarlyePaymentDiscount(discounts: InvoiceEarlyPaymentDiscount[]) {
      const discountsFinal: InvoiceEarlyPaymentDiscount[] = [];
      if (!discounts || discounts.length === 0) {
        return;
      }

      discounts.forEach((discount) => {
        if (!discount.discount || discount.discount.valueUnmasked === 0) {
          return;
        }
        const earlyPaymentDiscount: InvoiceEarlyPaymentDiscount = {
          days: discount.days,
          percent: discount.discount.unit === 'percentual' ? +Helper.sentPercent(discount.discount.valueUnmasked) : undefined,
          valueCents: discount.discount.unit === 'cents' ? discount.discount.valueUnmasked : undefined,
        };
        discountsFinal.push(earlyPaymentDiscount);
      });
      return discountsFinal;
    },

    getLimitNumbersOfDiscount(): number {
      return 2;
    },

    async duplicate(invoiceId: string, dueDate: string) {
      const response = await provider.core.post(`${this.baseUrl}/${invoiceId}/duplicate`, {
        dueDate
      });
      return response.data;
    },

    async payInternal(paymentMethod: string, invoiceId: string) {
      return provider.core.post(`/api/ebpay/invoice/${invoiceId}/pay-internal`, {
        paymentMethod
      });
    },

    async payLiquidateInternal(paymentMethod: string, invoiceId: string) {
      return provider.core.post(`/api/ebpay/invoice/${invoiceId}/liquidate-internal`, {
        paymentMethod
      });
    },

    async getCreditCardTaxResponsible(): Promise<OptionsSelectInput[]> {
      const response = await provider.core.get(`/api/ebpay/invoice/credit-card-tax-responsible`);
      return response.data;
    },

    async getCardMachineTaxResponsible(): Promise<OptionsSelectInput[]> {
      const response = await provider.core.get(`/api/ebpay/invoice/card-machine-tax-responsible`);
      return response.data;
    },

    async sendPaymentCreditCardToZoop(data: CreditCardCore, marketplaceId: string) {
      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: "Basic " + process.env.VUE_APP_ZOOP_AUTHORIZATION
        },
        body: JSON.stringify({
          holder_name: data.name,
          expiration_month: Date.getDateCreditCard(data.dueDate, 'month'),
          expiration_year: Date.getDateCreditCard(data.dueDate, 'year'),
          card_number: Format.noSpaces(data.number),
          security_code: data.cvc
        })
      };

      return await fetch(`https://api.zoop.ws/v1/marketplaces/${marketplaceId}/cards/tokens`, options)
        .then(response => response.json())
    },

    async payByCreditCard(invoiceId = '', token = '', cardBrand = '', installments = 1) {
      await provider.core.post(`${this.baseUrl}/pay-by-credit-card`, {
        invoiceId: invoiceId,
        token: token,
        cardBrand: Format.noSpaces(cardBrand),
        numberOfInstallments: installments
      });
    },

    async getCreditCardData(cardBrand = '', invoiceId = '') {
      const response = await provider.core.get(`${this.baseUrl}/${invoiceId}/credit-card-data?cardBrand=${cardBrand}`);
      return response.data;
    },

    async getInvoiceStatus() {
      const response = await provider.core.get(`${this.baseUrl}/invoice-status`);
      return response.data;
    },

    async cancelMany(ids = [] as string[], comment = '') {
      const response = await provider.core.post(`${this.baseUrl}/cancel-many`, {
        invoiceIdList: ids,
        comment: comment
      });

      return response.data;
    },

    async createInvoiceComment(invoiceComment: InvoiceComment) {
      const response = await provider.core.post(`/api/ebpay/invoice/comment`, {
        invoiceId: invoiceComment.invoiceId,
        comment: invoiceComment.comment,
        eventType: invoiceComment.eventType
      });
      return response.data;
    },

    async deleteInvoiceComment(id: string): Promise<void> {
      await provider.core.delete(`api/ebpay/invoice/comment/${id}`);
    },

    async revertPaymentAtSchool(invoiceId = '', comment = '') {
      const response = await provider.core.post(`/api/ebpay/invoice/${invoiceId}/revert-payment-at-school`, {
        comment
      });
      return response.data;
    },

    async sendInvoicesPreEnrollmentToPdf() {
      const response = await provider.core.post('/api/ebpay/invoices-download-request/to-pre-enrollment-invoices');
      return response.data;
    },

    async hasOpenInvoices(): Promise<{data: boolean}> {
      return provider.core.get(`api/ebpay/invoice/has-open-invoices`);
    },

    async emitInvoices(): Promise<any> {
      return provider.core.post(`api/ebpay/invoice/close-open-invoices`);
    },
  };
};

export default service();
