import axios from "axios";
import subdomain from "@/helpers/subdomain";
import { errorProvider } from "@/app/config/providers/error";
import IntegrationOidc from "@/app/integrations/oidc";
import { userStore } from "@/modules/users/store";
import permission from "@/app/permission";

const CREDIT = axios.create({
  baseURL: `${ process.env.VUE_APP_PROTOCOL }${ subdomain }${ process.env.VUE_APP_PROVIDER_CREDIT }`,
  headers: { 'Access-Control-Allow-Origin': '*' },
  withCredentials: true
});

CREDIT.interceptors.response.use(response => response, function (error) {
  return errorProvider(error);
});

CREDIT.interceptors.request.use(async (config) => {
    const tokenUser = await IntegrationOidc.Mgr().getUser()

    if (tokenUser) {
      if (tokenUser.access_token) {
        const newToken = tokenUser.expired ? await IntegrationOidc.renewToken() : tokenUser.access_token
        config.headers.Authorization = `Bearer ${ newToken }`;

        if (
          userStore.state.user.tenantSelected &&
          userStore.state.user.tenantSelected.id &&
          permission.isHost() &&
          !(/feature-management\/features/.test(config.url))
        ) {
          config.headers.tenantId = userStore.state.user.tenantSelected.id
          config.headers.tenantName = userStore.state.user.tenantSelected.name
        }
      }
    }
    return config;
  },
  (err) => {
    console.log(`error interceptors provider CREDIT`, err);
  });

export { CREDIT }
