import {RouteRecordRaw} from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/configuracoes',
    name: 'configurations',
    component: () => import(/* webpackChunkName: "main" */ '@/modules/configurations/ConfigurationsPanel.vue'),
  },
  {
    path: '/configuracoes/redes',
    name: 'tenants',
    component: () => import(/* webpackChunkName: "tenants" */ '@/modules/configurations/tenants/views/TenantList.vue'),
    meta: {permission: 'Educbank.Tenant'},
  },
  {
    path: '/configuracoes/escolas',
    name: 'schools',
    component: () => import(/* webpackChunkName: "schools" */ '@/modules/configurations/schools/views/SchoolList.vue'),
    meta: {permission: 'Educbank.School'},
  },
  {
    path: '/configuracoes/escolas/:id',
    name: 'schoolDetails',
    component: () => import(/* webpackChunkName: "schools-details" */ '@/modules/configurations/schools/views/SchoolDetails.vue'),
    meta: {permission: 'Educbank.School'},
  },
  {
    path: '/configuracoes/gatilhos',
    name: 'webhooks',
    component: () => import(/* webpackChunkName: "webhooks" */ '@/modules/configurations/webhook/views/ListWebhook.vue'),
    meta: {permission: 'Educbank.Webhook'},
  },
  {
    path: '/configuracoes/logs/audit',
    name: 'logsAudit',
    component: () => import(/* webpackChunkName: "logsAudit" */ '@/modules/configurations/logs-list/audit/views/ListAuditLogs.vue'),
    meta: {permission: 'Educbank.AuditLog'},
  },
  {
    path: '/configuracoes/usuarios',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '@/modules/configurations/access/users/views/ListUsers.vue'),
    meta: {permission: 'AbpIdentity.Users'},
  },
  {
    path: '/configuracoes/logs/webhooks',
    name: 'webhooksLogs',
    component: () => import(/* webpackChunkName: "webhooksLogs" */ '@/modules/configurations/logs-list/webhooks/views/ListWebhookLogs.vue'),
    meta: {permission: 'Educbank.GatewayWebhookLog'},
  },
  {
    path: '/configuracoes/grupos',
    name: 'roles',
    component: () => import(/* webpackChunkName: "roles" */ '@/modules/configurations/access/roles/views/RolesList.vue'),
    meta: {permission: 'AbpIdentity.Roles'},
  },
  {
    path: '/configuracoes/parametros',
    name: 'parameters',
    component: () => import(/* webpackChunkName: "tenants" */ '@/modules/configurations/parameters/views/ListParameters.vue'),
    meta: {permission: 'Educbank.Tenant'},
  },
  {
    path: '/configuracoes/eventos',
    name: 'events',
    component: () => import(/* webpackChunkName: "webhooks" */ '@/modules/configurations/logs-list/events/views/ListEventsLogs.vue'),
    meta: {permission: 'Educbank.Events'},
  },
  {
    path: 'configuracoes/maquininha',
    name: 'cardMachine',
    component: () => import(/* webpackChunkName: "cardMachine" */ '@/modules/configurations/card-machines/views/CardMachines.vue')
  },
  {
    path: 'configuracoes/produtos',
    name: 'cardProducts',
    component: () => import(/* webpackChunkName: "cardMachine" */ '@/modules/configurations/products/views/ProductsList.vue')
  },
  {
    path: '/configuracoes/notificacoes',
    name: 'notifications',
    component: () => import(/* webpackChunkName: "adminModule" */ '@/modules/configurations/notifications/views/NotificationsSettings.vue'),
  },
  {
    path: '/configuracoes/churn',
    name: 'churn',
    component: () => import(/* webpackChunkName: "churn" */ '@/modules/configurations/churn/views/ChurnList.vue'),
  },
  {
    path: '/configuracoes/churn/:id',
    name: 'churnDetails',
    component: () => import(/* webpackChunkName: "churn" */ '@/modules/configurations/churn/views/ChurnDetails.vue'),
  },
  {
    path: '/configuracoes/plano-educbank',
    name: 'educbankPlan',
    component: () => import(/* webpackChunkName: "educbankPlan" */ '@/modules/configurations/educbank-plan/views/EducbankPLanList.vue'),
  },
  {
    path: '/configuracoes/plano-educbank/:id',
    name: 'educbankPlanDetails',
    component: () => import(/* webpackChunkName: "educbankPlan" */ '@/modules/configurations/educbank-plan/views/EducbankPLanDetails.vue'),
  },
  {
    path: '/configuracoes/cursos',
    name: 'courses',
    component: () => import(/* webpackChunkName: "courses" */ '@/modules/configurations/course/views/CourseList.vue'),
  },
]

export default routes
