import {RouteRecordRaw} from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/faturas',
    name: 'invoices',
    component: () => import(/* webpackChunkName: "invoices" */ '@/modules/invoice/views/InvoiceList.vue'),
  },
  {
    path: '/faturas/:id',
    name: 'invoicesDetails',
    component: () => import(/* webpackChunkName: "invoices" */ '@/modules/invoice/views/DetailsInvoicePage.vue'),
  },
  {
    path: '/invoices',
    component: () => import(/* webpackChunkName: "invoices" */ '@/modules/invoice/views/InvoiceList.vue'),
  },
  {
    path: '/invoices/:id',
    component: () => import(/* webpackChunkName: "invoices" */ '@/modules/invoice/views/DetailsInvoicePage.vue'),
  },
]

const invoiceCustom: RouteRecordRaw = {
  path: '/invoice/get-bankslip/:id',
  name: 'customInvoice',
  component: () => import(/* webpackChunkName: "invoices" */ '@/modules/invoice/views/CustomInvoice.vue'),
}

export default routes
export {invoiceCustom}
