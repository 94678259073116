import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/cobrancas',
    name: 'billings',
    component: () => import(/* webpackChunkName: "billings" */ '@/modules/billing/views/BillingManagement.vue'),
  },
  {
    path: '/cobrancas/:id',
    name: 'debtsDetails',
    component: () => import(/* webpackChunkName: "debts-details" */ '@/modules/billing/views/DebtsDetails.vue'),
  },
  {
    path: '/negativacao/:id',
    name: 'negativeDetails',
    component: () => import(/* webpackChunkName: "negative-details" */ '@/modules/billing/views/NegativeDetails.vue'),
  },
]

export default routes
