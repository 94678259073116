import {RouteRecordRaw} from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/matriculas',
    name: 'studentArea',
    component: () => import(/* webpackChunkName: "student-area" */ '@/modules/student-area/views/StudentAreaList.vue'),
  },
  {
    path: '/matriculas/:id',
    name: 'studentAreaDetails',
    component: () => import(/* webpackChunkName: "student-area-details" */ '@/modules/student-area/views/StudentAreaDetails.vue'),
  },
  {
    path: '/matriculas/acordo/:id',
    name: 'agreementDetails',
    component: () => import(/* webpackChunkName: "enrollments-agreement" */ '@/modules/student-area/views/AgreementDetails.vue'),
  },
]

export default routes
