/**
 * This file represents a wai manipulate a http providers
 * You can pass any http provider like a fetch and axios
 */

type Gateway = 'core' | 'identity' | 'gatewaytransfer' | 'public' | 'invoiceview' | 'cession' | 'credit' | 'pagarme' | 'courier'

const index = () => {
  return {
    core: undefined as any,
    identity: undefined as any,
    gatewaytransfer: undefined as any,
    public: undefined as any,
    invoiceview: undefined as any,
    cession: undefined as any,
    credit: undefined as any,
    pagarme: undefined as any,
    courier: undefined as any,
    /**
     *
     * @param name - represent name and form you get provider in a future like: provider.NAME
     * @param provider
     * @returns {*}
     */
    addProvider(name: Gateway, provider: any) {
      if (name == null || name.length === 0) {
        throw new Error('You need inform a valid provider name');
      }

      this[name] = provider;
    },
  };
};

export default index();
