<template>
  <em :class="`ph-${icon}`" :style="`font-size: ${fontSize}rem; color:${color}`" v-bind="$attrs"/>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "AthIcon",
  inheritAttrs: false,
  props: {
    icon: {required: true, type: String},
    fontSize: {require: true, type: [Number, String], default: '1.2'},
    color: {type: String, default: null},
  },
});
</script>
