import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/turmas',
    name: 'classes',
    component: () => import(/* webpackChunkName: "academic-class" */ '@/modules/academicClass/views/AcademicClassList.vue'),
  },
  {
    path: '/turmas/:id',
    name: 'classDetails',
    component: () => import(/* webpackChunkName: "academic-class" */ '@/modules/academicClass/views/AcademicClassDetails.vue'),
  },
]

export default routes
