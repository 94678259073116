import { debounce } from "lodash";
import Alert from "@/helpers/AlertHelper";
import Event from "@/helpers/event";
import subdomain from "@/helpers/subdomain";
import SecurityService from "@/app/integrations/oidc";
import { NotificationEventStatusEnum } from "@/modules/invoice/types/invoiceEvent.enum"

const SingletonInstanceSymbol = Symbol()
const InstanceSymbol = Symbol()

const processNotification = (type, message) => {
  switch (type) {
    case 'MessageUser':
      Alert.notify('error', message, '', '', false, 1000000000000000)
      break;
    case 'closeinvoice':
      Event.emit('signalRCloseInvoice', message)
      break;
    case 'ImportNotification':
      Event.emit('signalRImportNotification', message)
      break;
    case 'ExportNotification':
      Event.emit('signalRExportNotification', message)
      break;
    default:
      console.warn(`Unknown notification type: ${type}`)
  }
}

const debouncedNotificationQueue = debounce(processNotification, 5000)

class SignalR {
  constructor(instance) {
    if (instance !== SingletonInstanceSymbol) {
      throw new Error('Cannot Construct SignalR Instance')
    }

    this.worker = new Worker(new URL('./signalRWebWorker.js', import.meta.url))
    this.worker.onmessage = this.handleWorkerMessage.bind(this)
    this.worker.onerror = (error) => {
      console.error('Worker error:', error)
    }
  }

  static get instance() {
    if (!this[InstanceSymbol]) {
      this[InstanceSymbol] = new SignalR(SingletonInstanceSymbol)
    }
    return this[InstanceSymbol]
  }

  async handleWorkerMessage(event) {
    const { type, message } = event.data

    if (message.backgroundJobStatus === NotificationEventStatusEnum.InProgress) {
      processNotification(type, message)
    } else {
      debouncedNotificationQueue(type, message)
    }
  }

  async initConnections() {
    const user = await SecurityService.Mgr().getUser()

    if (!user) return

    const protocol = process.env.VUE_APP_PROTOCOL === 'https://' ? 'https://' : 'http://'
    const baseUrl = `${protocol}${subdomain}${process.env.VUE_APP_PROVIDER_GATEWAY}`

    const urls = [
      `${baseUrl}/signalr-hubs/message-user`,
      `${baseUrl}/bulk-invoice-generation/hub`,
      `${baseUrl}/signalr-hubs/import`,
      `${baseUrl}/signalr-hubs/export`
    ];

    this.worker.postMessage({ type: 'init', urls, user })
  }
}

export default SignalR.instance;
