import {configure, defineRule} from 'vee-validate';
import {required} from '@vee-validate/rules';
import {localize, setLocale} from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import pt_BR from '@vee-validate/i18n/dist/locale/pt_BR.json';
import i18n from "@/i18n";

defineRule('required', required);

configure({
  validateOnInput: true,
	generateMessage: localize({
		en,
		pt_BR,
	}),
});
if (i18n.global.t('CultureSelected') === 'en') {
	setLocale('en');
} else {
	setLocale('pt_BR');
}
