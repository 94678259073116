import provider from '@/provider';
import {BankAccountCore} from "@/modules/configurations/educbank-plan/types/plan.types";
import {TenantCore} from "@/modules/configurations/tenants/types/tenant.types";

export interface FeatureManagementCore {
  displayName: string
  name: string
  features: FeatureCore[]
}

export interface FeatureCore {
  depth?: number
  description: string
  displayName: string
  name: string
  value: string | boolean
}

const service = () => {
  return {
    async getAllTenants(params = ''): Promise<{ items: TenantCore[]; totalCount: number }> {
      const response = await provider.core.get(`api/ebpay/educbank-tenant${params}`);
      return response.data;
    },

    async getFeatureTenant(providerName: string, providerKey: string): Promise<{ groups: FeatureManagementCore[] }> {
      const response = await provider.core.get(`api/feature-management/features?providerName=${providerName}&providerKey=${providerKey}`);
      return response.data;
    },

    async getBanks(): Promise<BankAccountCore[]> {
      const response = await provider.core.get(`api/ebpay/bank-integration`);
      return response.data.items;
    },
  };
};

export default service();
