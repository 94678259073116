import {useUserState} from "@/modules/users/store";
import IntegrationOidc from "@/app/integrations/oidc";
import feature from "@/app/feature";
import permission from "@/app/permission";
import termOfService from "@/modules/terms/services/termOfService";
import router from "@/app/router/instance";
import {Router} from "vue-router";

const verifyAuthBeforeEachRouterEnter = (router: Router) => {
  router.beforeEach(async (to, _from, next) => {
    if (useUserState().isAuthenticated) {
      next();
    } else if (to.matched.some(record => record.meta.requiresAuth)) {
      await IntegrationOidc.authenticate(to.path, to.query.realm).then(async () => {
        await verifyUserRedirectToTerms(next, to.path)
      });

      if (to.matched.some(record => record.meta.feature && feature.hasFeature(record.meta.feature) === 'false')) {
        router.push({name: 'main', params: {to: to.path}});
      }

      if (to.matched.some(record => record.meta.permission && permission.hasPermission(record.meta.permission) === false)) {
        router.push({name: 'main', params: {to: to.path}});
      }
    } else {
      next();
    }
  });
}

async function verifyUserRedirectToTerms(next: any, path = '') {
  const user = await IntegrationOidc.Mgr().getUser();
  if (user && !user.expired) {
    const terms = await termOfService.checkIfHasTermPending()

    if (terms.length === 0) {
      if (path.includes('/terms') || path.includes('/statementOfResponsibility')) {
        await router.push({name: 'studentArea'});
      }
      next();
    } else if (terms.includes('PrivacyPolicy')) await router.push({name: 'terms'})
    else await router.push({name: 'statementOfResponsibility'})
  }
}

export default verifyAuthBeforeEachRouterEnter
