import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/integracao/remessa',
    meta: { feature: 'Educbank.RemessaRetorno' },
    name: 'remessas',
    component: () => import(/* webpackChunkName: "remessas" */ '@/modules/integration/views/IntegrationRemessasList.vue'),
  },
  {
    path: '/integracao/remessa/:id',
    meta: { feature: 'Educbank.RemessaRetorno' },
    name: 'remessaDetails',
    component: () => import(/* webpackChunkName: "remessa-details" */ '@/modules/integration/views/IntegrationRemessaDetails.vue'),
  },
  {
    path: '/integracao/retorno',
    meta: { feature: 'Educbank.RemessaRetorno' },
    name: 'retornos',
    component: () => import(/* webpackChunkName: "retornos" */ '@/modules/integration/views/IntegrationRetornosList.vue'),
  },
  {
    path: '/integracao/retorno/:id',
    meta: { feature: 'Educbank.RemessaRetorno' },
    name: 'retornoDetails',
    component: () => import(/* webpackChunkName: "retorno-details" */ '@/modules/integration/views/IntegrationRetornoDetails.vue'),
  },
]

export default routes
