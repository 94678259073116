import provider from '@/provider';

export interface TermOfServiceCore {
	id: string
	title: string
	body: string
}

const service = () => {
	return {
		async getCurrentTerm(type = 'PrivacyPolicy'): Promise<TermOfServiceCore> {
			const response = await provider.core.get(`/api/ebpay/term-of-service/current?termOfServiceType=${type}`)
			return response.data;
		},

		async checkIfHasTermPending(): Promise<string[]> {
			const response = await provider.core.get(`/api/ebpay/term-of-service/terms-of-service-pending`);
			return response.data;
		},

		async acceptTerm(termOfServiceId: string) {
			const response = await provider.core.post(`/api/ebpay/term-of-service/accept-term-of-service/${termOfServiceId}`);
			return response.data;
		}
	}
}

export default service();
