import provider from '@/provider';
import IntegrationOidc from "@/app/integrations/oidc";
import userService from "@/modules/users/services/userService";

const R = require('ramda')

export interface SettingsCore {
  name: string
  value: string
  isProvidedGlobal: boolean
}

const service = () => {
  return {
    async getAll(tenantId = ''): Promise<SettingsCore[]> {
      const queryParams = tenantId ? `?TenantId=${tenantId}` : '';

      const response = await provider.core.get(`/api/ebpay/settings/settings${queryParams}`);
      const isEducbank = (setting: SettingsCore) => !setting.name.includes('Abp.');

      return R.filter(isEducbank, response.data)
    },

    async getAllIdentity(tenantId = ''): Promise<SettingsCore[]> {
      const queryParams = tenantId ? `?TenantId=${tenantId}` : '';

      const response = await provider.identity.get(`/api/identity/settings${queryParams}`);
      const isIdentity = (setting: SettingsCore) => setting?.name?.includes('IdentitySettings.');

      return R.filter(isIdentity, response.data)
    },

    async setSetting(name = '', value = '', providerName = '', providerKey = '', serviceName = 'Educbank'): Promise<void> {
      if (providerKey) providerName = 'T'

      return provider.core.post(`/api/ebpay/settings/setting`, {
        name: `${serviceName}.${name}`,
        value: `${value}`,
        providerName,
        providerKey,
      })
    },

    async setSettingNormal(name = '', value = '', providerKey = ''): Promise<void> {
      let providerName = 'G'
      if (providerKey) providerName = 'T'

      return provider.core.post(`/api/ebpay/settings/setting`, {
        name: `${name}`,
        value: `${value}`,
        providerName,
        providerKey,
      })
    },

    async getBySetting(setting = ''): Promise<SettingsCore> {
      const result = await IntegrationOidc.Mgr().getUser();
      const userData = await userService.getById(result!.profile?.sub)

      let settings = await this.getAll(userData.tenantId)
      settings = settings.filter((item) => item.name === setting)
      return settings[0]
    },

    async getSettingsForTenant(tenantId: string) {
      const allSettings = await this.getAll(tenantId);
      const objSettings: any = {};
      allSettings.forEach((setting) => {
        objSettings[setting.name] = setting.value
      })

      return objSettings
    }
  }
}

export default service();
