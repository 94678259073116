import {RouteRecordRaw} from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/transfers/remessa',
    meta: {permission: 'Educbank.BankTransferRemessa'},
    name: 'transferRemessas',
    component: () => import(/* webpackChunkName: "transfers" */ '@/modules/transfers-old/views/ListTransferRemessas.vue'),
  }
]

export default routes
