import { createStore } from 'vuex'
import { UserState, StateUserField } from '../types/user'

export enum UserActionTypes {
  CHANGE_IS_AUTHENTICATED = 'CHANGE_IS_AUTHENTICATED',
  CHANGE_IS_EXPIRED = 'CHANGE_IS_EXPIRED',
  CHANGE_IN_ADMIN_GROUP = 'CHANGE_IN_ADMIN_GROUP',
  SET_USER_NAME = 'SET_USER_NAME',
  SET_USER_EMAIL = 'SET_USER_EMAIL',
  SET_USER_TENANT = 'SET_USER_TENANT',
  SET_USER_All_TENANTS = 'SET_USER_All_TENANTS',
  SET_USER_TENANT_SELECTED = 'SET_USER_TENANT_SELECTED',
  SET_USER_SKIP_COUNT_TENANT = 'SET_USER_SKIP_COUNT_TENANT',
  SET_USER_MAX_RESULT_COUNT_TENANT = 'SET_USER_MAX_RESULT_COUNT_TENANT',
  SET_USER_ID = 'SET_USER_ID',
}

function initialState() {
  return {
    isAuthenticated: false,
    expired: true,
    userName: '',
    email: '',
    id: '',
    tenant: undefined,
    allTenants: [],
    tenantSelected: undefined,
    skipCountTenant: 0,
    maxResultCountTenant: 50,
    inGroupAdmin: false,
  } as StateUserField
}

export const userStore = createStore<UserState>({
  state: {
    user: initialState(),
  },
  actions: {
    [UserActionTypes.CHANGE_IS_AUTHENTICATED]({ commit }, payload) {
      commit(UserActionTypes.CHANGE_IS_AUTHENTICATED, payload)
    },
    [UserActionTypes.CHANGE_IS_EXPIRED]({ commit }, payload) {
      commit(UserActionTypes.CHANGE_IS_EXPIRED, payload)
    },
    [UserActionTypes.SET_USER_NAME]({ commit }, payload) {
      commit(UserActionTypes.SET_USER_NAME, payload)
    },
    [UserActionTypes.SET_USER_EMAIL]({ commit }, payload) {
      commit(UserActionTypes.SET_USER_EMAIL, payload)
    },
    [UserActionTypes.SET_USER_TENANT]({ commit }, payload) {
      commit(UserActionTypes.SET_USER_TENANT, payload)
    },
    [UserActionTypes.SET_USER_All_TENANTS]({ commit }, payload) {
      commit(UserActionTypes.SET_USER_All_TENANTS, payload)
    },
    [UserActionTypes.SET_USER_TENANT_SELECTED]({ commit }, payload) {
      commit(UserActionTypes.SET_USER_TENANT_SELECTED, payload)
    },
    [UserActionTypes.SET_USER_SKIP_COUNT_TENANT]({ commit }, payload) {
      commit(UserActionTypes.SET_USER_SKIP_COUNT_TENANT, payload)
    },
    [UserActionTypes.SET_USER_MAX_RESULT_COUNT_TENANT]({ commit }, payload) {
      commit(UserActionTypes.SET_USER_MAX_RESULT_COUNT_TENANT, payload)
    },
    [UserActionTypes.CHANGE_IN_ADMIN_GROUP]({ commit }, payload) {
      commit(UserActionTypes.CHANGE_IN_ADMIN_GROUP, payload)
    },
    [UserActionTypes.SET_USER_ID]({ commit }, payload) {
      commit(UserActionTypes.SET_USER_ID, payload)
    },
  },
  mutations: {
    [UserActionTypes.CHANGE_IS_AUTHENTICATED](state, payload) {
      state.user.isAuthenticated = payload
    },
    [UserActionTypes.CHANGE_IS_EXPIRED](state, payload) {
      state.user.expired = payload
    },
    [UserActionTypes.SET_USER_NAME](state, payload) {
      state.user.userName = payload
    },
    [UserActionTypes.SET_USER_EMAIL](state, payload) {
      state.user.email = payload
    },
    [UserActionTypes.SET_USER_TENANT](state, payload) {
      state.user.tenant = payload
    },
    [UserActionTypes.SET_USER_All_TENANTS](state, payload) {
      state.user.allTenants = payload
    },
    [UserActionTypes.SET_USER_TENANT_SELECTED](state, payload) {
      state.user.tenantSelected = payload
    },
    [UserActionTypes.SET_USER_SKIP_COUNT_TENANT](state, payload) {
      state.user.skipCountTenant = payload
    },
    [UserActionTypes.SET_USER_MAX_RESULT_COUNT_TENANT](state, payload) {
      state.user.maxResultCountTenant = payload
    },
    [UserActionTypes.CHANGE_IN_ADMIN_GROUP](state, payload) {
      state.user.inGroupAdmin = payload
    },
    [UserActionTypes.SET_USER_ID](state, payload) {
      state.user.id = payload
    },
  },
  getters: {
    user(state) {
      return state.user
    },
    userName(state) {
      return state.user.userName
    },
    allTenants(state) {
      return state.user.allTenants
    },
    tenantSelected(state) {
      return state.user.tenantSelected
    },
    userEmail(state) {
      return state.user.email
    },
    userTenant(state) {
      return state.user.tenant
    },
  },
})

export function useUserState() {
  return userStore.state.user
}
