<template>
  <div :class="'block-ui'" :data-bc="$options.name">
    <div id="preloader" class="preloader">
      <div style="text-align: center;z-index: 9999;color: white">
        <div class="d-flex align-items-center justify-content-center">
          <article>
            <img class="img-logo" src="@/assets/images/logo-white.png" alt='img-logo' width="115">
            <div v-if="text"
                 class="ath-text-body ath-font-size--18 ath-color--white"
                 style="white-space: pre-wrap">
              {{ text }}
            </div>
            <div class='loadingBar'></div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AthLoader",
  props: {
    text: { default: '' },
    size: { default: 'lg' },
  },
  data: () => ({
    defaultText: null,
  }),
  mounted() {
    this.defaultText = this.text;
    const element = this.$el.querySelector('#preloader');
    element.setAttribute("style", `height:${ window.innerHeight }px!important`);
    window.addEventListener('resize', () => {
      element.setAttribute("style", `height:${ window.innerHeight }px!important`);
    });
  },
});
</script>
<style lang="scss">
.block-ui {
  background-color: rgba(29, 34, 45, 0.85);
  z-index: 10000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-items: center;
  text-align: center;
  position: fixed;

  & > .preloader {
    position: absolute;
    opacity: 1;
    width: 100%;
    display: flex;
    justify-items: center;
    margin-left: auto;
    align-items: center;

    > div {
      width: 100%
    }
  }
}

article {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.3rem;

  & .loadingBar {
    position: relative;
    width: 12rem;
    height: 0.35rem;
    background: var(--ath-color-light);
    border-radius: 0.5em;
    transition: width 0.08s ease-in;
    overflow-x: hidden;
    animation-fill-mode: forwards;

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      height: 100%;
      width: 4.5rem;
      left: 0;
      background: #baacff;
      animation: moveLoadingBar 1.8s ease infinite;
    }
  }
}

// ------------> Loading Bar Animations

@keyframes moveLoadingBar {
  0% {
    transform: translateX(0rem);
  }
  50% {
    transform: translateX(7.8rem);
  }
  100% {
    transform: translateX(0rem);
  }
}

@keyframes shrink {
  to {
    width: 0;
  }
}

@keyframes removeLogo {
  from {
    opacity: 1;
    transform: translateY(0rem);
  }
  to {
    opacity: 0;
    transform: translateY(0.5rem);
  }
}
</style>
