import Helper from "@/helpers/helper";

const installDirectives = (app: any) => {

  app.directive("click-outside", {
    beforeMount: function (el: any, binding: any) {
      const ourClickEventHandler = (event: any) => {
        if (!el.contains(event.target) && el !== event.target && event.target.accessKey != 'v-click-outside') {
          binding.value(event);
        }
      };
      el.__vueClickEventHandler__ = ourClickEventHandler;
      document.addEventListener("click", ourClickEventHandler);
    },
    unmounted: function (el: any) {
      document.removeEventListener("click", el.__vueClickEventHandler__);
    }
  })

  app.directive('sensitive-data', async (el: any, binding: any) => {
    if (binding.value) {
      el.innerText = Helper.verifySensitiveData(binding.value)
    }
  })
}

export default installDirectives
